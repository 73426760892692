"use client";
import React, { useEffect, useMemo, useState } from "react";
import Link from "next/link";
import { Session } from "next-auth";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import {
  getUnreadMessagesCount,
  getUnreadMessagesWithChatroomId,
} from "@queries/chatQueries";
import { pusherClient } from "@lib/pusher";
import { Message } from "@prisma/client";
import { useNotification } from "@context/notification-provider";
import { useParams, usePathname } from "next/navigation";
// import { toast } from "react-toastify";
import toast from "react-hot-toast";
import Image from "next/image";
import { getProfileImage } from "@lib/helpers";
import { IMessage } from "@app/(www)/chat/_components/Conversation/Conversation";
import { IoMdClose } from "react-icons/io";

interface ChatNotificationProps {
  session: Session;
}
const ChatNotification = ({ session }: ChatNotificationProps) => {
  const { unreadMessages, setUnreadMessages } = useNotification();

  // const [unreadMessages, setUnreadMessages] = useState<Record<string, number>>(
  //   {}
  // );

  useEffect(() => {
    const fetchData = async () => {
      const messages = await getUnreadMessagesWithChatroomId(session?.user?.id);
      setUnreadMessages(messages);
    };
    fetchData();
  }, [session?.user?.id, setUnreadMessages]);

  const params = useParams();
  const pathname = usePathname();

  useEffect(() => {
    const channel = pusherClient.subscribe(session?.user?.id); // Subscribe to the channel where new chatrooms are broadcasted

    channel.bind("chatroom:new-msg", async (newMessage: IMessage) => {
      if (params.chatroomId !== newMessage.chatroomId) {
        if (!pathname.startsWith("/chat")) {
          let imageUrl: string = "";

          if (newMessage.sender?.role === "EMPLOYER") {
            imageUrl = getProfileImage({
              id: newMessage.sender?.id,
              name: newMessage.sender?.employer_profile?.companyName || "",
              image: newMessage.sender.employer_profile?.companyLogo || "",
            });
          }
          if (newMessage.sender?.role === "CANDIDATE") {
            imageUrl = getProfileImage({
              id: newMessage.sender?.id,
              name: newMessage.sender?.firstName || "",
              image: newMessage.sender.profile?.profileImage || "",
            });
          }

          toast.custom(
            (t) => (
              <div className="flex w-full max-w-xs rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 relative group">
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    toast.remove(t.id);
                  }}
                  className="text-lg absolute top-3 right-3 z-10 shrink-0 text-dark-1 hover:text-dark-1/70 opacity-0 group-hover:opacity-100"
                >
                  <IoMdClose />
                </button>
                <Link
                  href={`/chat/${newMessage.chatroomId}`}
                  onClick={() => toast.remove(t.id)}
                  className="w-0 flex-1 p-4"
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0 pt-0.5">
                      <Image
                        src={imageUrl}
                        alt={
                          (newMessage.sender?.role === "CANDIDATE"
                            ? `${newMessage.sender?.firstName} ${newMessage.sender?.lastName}`
                            : newMessage.sender?.employer_profile
                                ?.companyName) || ""
                        }
                        className="w-11 h-11 rounded-full object-contain object-center"
                        width={44}
                        height={44}
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        {(newMessage.sender?.role === "CANDIDATE"
                          ? `${newMessage.sender?.firstName} ${newMessage.sender?.lastName}`
                          : newMessage.sender?.employer_profile?.companyName) ||
                          ""}
                      </p>
                      <p className="mt-1 text-sm text-gray-500 truncate">
                        {newMessage.content}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ),
            { position: "bottom-left" }
          );
        }

        setUnreadMessages((prevState) => {
          const prevStateCopy = { ...prevState };

          prevStateCopy[newMessage.chatroomId] =
            (prevStateCopy?.[newMessage.chatroomId] || 0) + 1;

          return prevStateCopy;
        });
      }
      // setUnreadMessagesCount((prevCount) => prevCount + 1);
    });

    return () => {
      channel.unbind();
      // channel.unsubscribe();
    };
  }, [params.chatroomId, pathname, session?.user?.id]);

  const unreadMessagesCount = useMemo(() => {
    let totalMessages = 0;
    for (let key in unreadMessages) {
      totalMessages += unreadMessages[key];
      // if (key !== params.chatroomId) {
      // }
    }
    return totalMessages;
  }, [unreadMessages]);

  return (
    // <div>
    <Link
      href="/chat"
      className="bg-dark-2 shadow border border-white/20 p-2 rounded-lg inline-block relative"
    >
      <IoChatbubbleEllipsesOutline className="text-2xl text-white" />
      {unreadMessagesCount ? (
        <div className="-top-2 -right-2 w-6 h-6 flex justify-center items-center text-sm font-medium rounded-full text-white bg-secondary absolute">
          <span>{unreadMessagesCount}</span>
        </div>
      ) : null}
    </Link>
    // </div>
  );
};

export default ChatNotification;

import React from "react";
import { classNames } from "@lib/helpers";

interface InputLabelProps {
  id?: string;
  label: string;
}
const InputLabel = ({ id, label }: InputLabelProps) => {
  return (
    <label
      htmlFor={id}
      className={classNames(
        "inline-block leading-6 text-gray-900 mb-2"
      )}
    >
      {label?.[label.length - 1] === "*" ? (
        <>
          {label.slice(0, -1)}
          <span className="text-red-500">*</span>
        </>
      ) : (
        <>{label}</>
      )}
    </label>
  );
};

export default InputLabel;

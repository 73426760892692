"use client";

import { pusherClient } from "@lib/pusher";
import { Message } from "@prisma/client";
import { Session } from "next-auth";
import { usePathname } from "next/navigation";
// NotificationContext.js
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface INotificationContext {
  unreadMessagesCount: number;
  setUnreadMessagesCount: React.Dispatch<React.SetStateAction<number>>;
  unreadMessages: Record<string, number>;
  setUnreadMessages: React.Dispatch<
    React.SetStateAction<Record<string, number>>
  >;
}
// Create the context
const NotificationContext = createContext<any>(null);

// Custom hook for consuming the context
export const useNotification = () =>
  useContext<INotificationContext>(NotificationContext);

// NotificationProvider component
export const NotificationProvider = ({
  children,
  session,
}: {
  children: ReactNode;
  session: Session | null;
}) => {
  //   const [notifications, setNotifications] = useState<any[]>([]);

  //   const addNotification = (notification) => {
  //     setNotifications((prevNotifications) => [
  //       ...prevNotifications,
  //       notification,
  //     ]);
  //   };

  //   const removeNotification = (id) => {
  //     setNotifications((prevNotifications) =>
  //       prevNotifications.filter((notification) => notification.id !== id)
  //     );
  //   };

  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const [unreadMessages, setUnreadMessages] = useState<Record<string, number>>(
    {}
  );

  return (
    <NotificationContext.Provider
      value={{
        unreadMessagesCount,
        setUnreadMessagesCount,
        unreadMessages,
        setUnreadMessages,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

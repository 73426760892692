import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/assets/images/logo.svg");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/Feedback/Feedback.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/ui/Layout/Footer/TidioChat.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/vhosts/wazifame.com/httpdocs/src/components/ui/Layout/Header/HeaderClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationProvider"] */ "/var/www/vhosts/wazifame.com/httpdocs/src/context/notification-provider.tsx");

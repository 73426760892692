import { type User } from "@prisma/client";

interface NextAuthUser extends User {
  name: string;
}

import { create } from "zustand";
type UserOnboardingState = {
  user: Partial<NextAuthUser> | null;
  setUser: (value: boolean) => void;
};

export const useUserOnboardingStore = create<UserOnboardingState>((set) => ({
  user: null,
  setUser: (values: Partial<NextAuthUser>) => set(() => ({ user: values })),
}));

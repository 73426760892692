import { classNames } from "@lib/helpers";
import React, { InputHTMLAttributes } from "react";
import InputLabel from "./InputLabel";
import { cn } from "@lib/utils";

export interface TextInputTypes extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  id?: string;
  label?: string;
  variant?: "full-rounded" | "default";
  inputSize?: "small" | "default" | "large";
  error?: string | boolean;
  isNotFullWidth?: boolean;
  inputRef?: any;
  icon?: React.ReactNode;
}

const TextInput: React.FC<TextInputTypes> = ({
  variant = "default",
  inputSize = "default",
  label,
  id,
  className,
  error,
  isNotFullWidth,
  inputRef,
  icon,
  ...props
}) => {
  return (
    <div className={isNotFullWidth ? "" : `w-full ${ (icon) ? "flex" : '' }`}>
      {label ? (
        <>
          <InputLabel id={id} label={label} />
          <br />
        </>
      ) : null}
      <div className="relative items-center flex w-full">
        {icon ? (
          <div className="absolute pl-4 text-gray-400">
            {icon}
          </div>
        ) : null}
        <input
          ref={inputRef}
          className={cn(
            variant === "default"
              ? "border border-gray-300 rounded-[10px] sm:rounded-lg focus:border-secondary"
              : "",
            variant === "full-rounded"
              ? "border md:border-2 border-[#EBEBFF] rounded-full focus:border-primary"
              : "",
            "inline-block lg:text-dark-1 placeholder:text-dark-1 placeholder:text-opacity-60 focus:outline-none focus:ring-0 disabled:bg-[#edf9fb] disabled:opacity-80 bg-white/10 lg:bg-white",
            inputSize === "large" ? "py-4 md:py-[16px] px-4 sm:px-6 text-lg" : "",
            inputSize === "default" ? "py-3 sm:py-[16px] px-4 sm:px-6" : "",
            inputSize === "small" ? "py-2.5 px-3 sm:px-4 text-[15px]" : "",
            icon ? "!pl-12" : "",
            className
          )}
          id={id}
          {...props}
        />
      </div>
      {error ? (
        <div
          className={classNames(
            "text-red-500 mt-1",
            inputSize === "small" ? "text-sm" : ""
          )}
        >
          {error}
        </div>
      ) : null}
    </div>
  );
};

export default TextInput;

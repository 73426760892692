import { Role } from "@prisma/client";

// Pagination
export const HOMEPAGE_FAQ_LIMIT = 8;
export const FAQ_DEFAULT_LIMIT = 10;

// Admin Roles
export const ADMIN_ROLES_OPTIONS = [
  { name: "Super Administrator", value: "SUPER_ADMINISTRATOR" },
  { name: "Admin", value: "ADMIN" },
  { name: "Client Admin", value: "CLIENT_ADMIN" },
  { name: "Accounts Admin", value: "ACCOUNTS_ADMIN" },
  { name: "Clients Accounts Admin", value: "CLIENTS_ACCOUNTS_ADMIN" },
  { name: "Content Moderator", value: "CONTENT_MODERATOR" },
  { name: "Customer Support", value: "CUSTOMER_SUPPORT" },
  { name: "Client Customer Support", value: "CLIENT_CUSTOMER_SUPPORT" },
  { name: "Client Content Moderator", value: "CLIENT_CONTENT_MODERATOR" },
];

export const EMPLOYER_TYPES = [
  { name: "User", value: "USER" },
  { name: "Admin", value: "ADMIN" },
];

export const ADMIN_ROLES: Role[] = [
  "SUPER_ADMINISTRATOR",
  "ADMIN",
  "CLIENT_ADMIN",
  "ACCOUNTS_ADMIN",
  "CLIENTS_ACCOUNTS_ADMIN",
  "CONTENT_MODERATOR",
  "CUSTOMER_SUPPORT",
  "CLIENT_CUSTOMER_SUPPORT",
  "CLIENT_CONTENT_MODERATOR",
];

// App Roles
export const APP_ROLES_OPTIONS = [
  { name: "Candidate", value: "CANDIDATE" },
  { name: "Employer", value: "EMPLOYER" },
];

// USER PACKAGES
export const PACKAGE_FOR_OBJECT = {
  JOB_SEEKER: "Job Seeker",
  EMPLOYER: "Employer",
  CV_SEARCH: "CV Search",
};

export const languageOptions = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "عربى",
    value: "ar",
  },
  {
    name: "French",
    value: "fr",
  },
];

export const validPaginationLimits = ["10", "20", "50", "100"];

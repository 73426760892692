import { Role } from "@prisma/client";
import {
  ADMIN_ROLES,
  ADMIN_ROLES_OPTIONS,
  APP_ROLES_OPTIONS,
} from "./constants";

export const isAdmin = (role: Role) => {
  return ADMIN_ROLES.includes(role);
};

export const getRole = (role: Role, rolesTranslation: any) => {
  const adminRoleName = ADMIN_ROLES_OPTIONS.find((r) => r.value === role)?.name;
  if (adminRoleName) return adminRoleName;
  let siteRoleValue = APP_ROLES_OPTIONS.find((r) => r.value === role)?.value;

  return rolesTranslation?.[siteRoleValue as string];
};

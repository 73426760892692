import { createContext } from "react";

export interface EmployerProfileImageType {
  setProfileImage: Function
}

const employerProfileimage: EmployerProfileImageType = {
  setProfileImage: () => {}
}

export const EmployerProfileImageContext = createContext(employerProfileimage);
import { classNames } from "@lib/helpers";
import { ReactNode, SelectHTMLAttributes } from "react";
import InputLabel from "./InputLabel";

// Define the IOption interface
export interface IOption {
  label?: string;  // Make label optional
  name?: string;   // Make name optional, for backward compatibility
  text?: string;   // Optional text
  value: string | number;  // Required value
}

// Define the SelectInput props interface
export interface SelectInputTypes
  extends SelectHTMLAttributes<HTMLSelectElement> {
  className?: string;
  id?: string;
  label?: string;
  variant?: "full-rounded" | "default" | "simple";
  inputSize?: "small" | "default" | "large";
  children?: ReactNode;
  error?: string | boolean;
  options?: IOption[];
  selectRef?: any;
  icon?: ReactNode;
}

const SelectInput: React.FC<SelectInputTypes> = ({
  variant = "default",
  inputSize = "default",
  label,
  id,
  className,
  children,
  error,
  options = [],
  selectRef,
  icon,
  ...props
}) => {
  return (
    <div className="w-full">
      {label ? (
        <>
          <InputLabel id={id} label={label} />
          <br />
        </>
      ) : null}
      <div className="relative items-center flex">
        {icon ? (
          <div className="absolute ml-2 text-gray-400">
            {icon}
          </div>
        ) : null}
        <select
          ref={selectRef}
          className={classNames(
            variant === "default"
              ? "border border-gray-300 rounded-[10px] sm:rounded-lg focus:border-secondary"
              : "",
            variant === "full-rounded"
              ? "border md:border-2 border-[#EBEBFF] rounded-full focus:border-primary"
              : "",
            "inline-block placeholder:text-dark-1 placeholder:text-opacity-60 focus:outline-none focus:ring-0",
            inputSize === "large" ? "py-3 sm:py-[16px] px-4 sm:px-6 text-lg" : "",
            inputSize === "default" ? "py-3 sm:py-[16px] px-4 sm:px-6" : "",
            inputSize === "small"
              ? "py-2.5 px-3 sm:px-4 sm:pr-9 sm:rtl:pr-4 sm:rtl:pl-9 text-[15px]"
              : "",
            icon ? "!pl-8" : "",
            className
          )}
          id={id}
          {...props}
        >
          {children}
          {options.length > 0 ? (
            options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label || option.name || option.text || "Select an option"}
              </option>
            ))
          ) : (
            <option value="">No options available</option>
          )}
        </select>
      </div>
      {error ? <div className="text-red-500 mt-1">{error}</div> : null}
    </div>
  );
};

export default SelectInput;

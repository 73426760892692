"use client";

import Script from "next/script";
import { useEffect, useRef } from "react";
import { FooterProps } from "./Footer";

export default function TidioChat({ session }: FooterProps) {

  const attemptCountRef = useRef(0);
  const maxAttempts = 3;

  useEffect(() => {
    // Function to check if Tidio Chat API is available
    const initializeTidioChatApi = () => {
      if (typeof window && (window as any).tidioChatApi) {
        const tidioChatApi = (window as any).tidioChatApi;
        const user = session?.user;       

        tidioChatApi.setVisitorData({
          name: user?.name,
          email: user?.email,
        });
      } else {
        // Retry after some delay if tidioChatApi is not yet available
        attemptCountRef.current++;
        if (attemptCountRef.current < maxAttempts) {
            setTimeout(initializeTidioChatApi, 1000);
        }
      }
    };

    // Initialize the API check
    initializeTidioChatApi();
  }, [session]);

  return (
    <>
      <Script
        src="//code.tidio.co/yjvkuln3effveza3hwcr4swcw3jmfql0.js"
        strategy="beforeInteractive"
        onLoad={() => {
          // Optional: Handle any additional setup if needed after script load
        }}
      />
    </>
  );
}

import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftEndOnRectangleIcon } from "@heroicons/react/24/outline";
import { Fragment, useRef } from "react";
import { useIntl } from "react-intl";

type Props = {
    open: boolean;
    setOpen: (value: boolean) => void;
    primaryClick: () => void;
}

export default function LogoutModal({ open, setOpen, primaryClick } : Props) {
    const cancelButtonRef = useRef(null);
    const intl = useIntl()
    const translation: any = intl.messages.header
    const profilePopupTranslation: any = translation['profile-popup']
    const logoutPopup = profilePopupTranslation['logout-popup']

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={setOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity backdrop-blur-[4px]" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden py-4 rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 py-5 sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:ml-4 sm:rtl:mr-4 sm:rtl:ml-0 sm:mt-0 sm:text-left sm:rtl:text-right">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-bold leading-6 text-gray-900"
                                            >
                                                {logoutPopup['title']}
                                            </Dialog.Title>
                                            <div className="mt-1.5">
                                                <p className="text-sm text-gray-500">
                                                {logoutPopup['description']}
                                                </p>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                                <div className="w-full justify-between px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 gap-3">
                                    <button
                                        type="button"
                                        className="p-4 inline-flex w-full justify-center rounded-xl bg-[#875500] px-5 py-2 text-lg font-medium text-white font-Poppins"
                                        onClick={() => primaryClick()}
                                    >
                                       <ArrowLeftEndOnRectangleIcon className="w-[30px] h-[30px] mr-2" /> {logoutPopup['primary-btn']}
                                    </button>
                                    <button
                                        type="button"
                                        className="p-4 inline-flex w-full justify-center rounded-xl bg-[#46aec6] px-5 py-2 text-lg font-medium text-white  sm:mt-0 font-Poppins"
                                        onClick={() => setOpen(false)}
                                        ref={cancelButtonRef}
                                    >
                                        {logoutPopup['secondary-btn']}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
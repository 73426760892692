import { useState, useEffect } from 'react';

export const isMobile = (): boolean => {
  if (typeof window !== 'undefined') {
    return window.innerWidth <= 768;
  }
  return false;
};

const useIsMobile = (): boolean => {
  const [mobile, setMobile] = useState<boolean>(isMobile());

  useEffect(() => {
    const handleResize = () => setMobile(isMobile());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return mobile;
};

export default useIsMobile;
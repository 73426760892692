import { classNames } from "@lib/helpers";
import { TextareaHTMLAttributes } from "react";
import InputLabel from "./InputLabel";

interface TextareaTypes extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string | boolean;
  textareaRef?: any;
}

const Textarea: React.FC<TextareaTypes> = ({
  label,
  error,
  id,
  textareaRef,
  ...props
}) => {
  return (
    <div className="w-full">
      {label ? (
        <>
          <InputLabel id={id} label={label} />
          <br />
        </>
      ) : null}
      <textarea
        ref={textareaRef}
        id={id}
        {...props}
        className={classNames(
          "w-full block text-dark-1 placeholder:text-opacity-60 leading-[1.25] text-base p-4 lg:p-5 rounded-2xl border border-[#E4E6EC] resize-none focus:ring-0 focus:border-gray-400",
          props.className
        )}
      />
      {error ? <div className="text-red-500 mt-1">{error}</div> : null}
    </div>
  );
};

export default Textarea;
